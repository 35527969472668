import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, Form, Button } from 'react-bootstrap';

import { statusCodes, createErrorNotification } from '../utils';
import { getAuthenticated } from '../services/api_requests';
import './styles/AuthView.css';

const INITIAL_STATE = {
  username: '',
  password: '',
};

const AuthView = ({ handleAuth }) => {
  const [username, setUsername] = useState(INITIAL_STATE.username);
  const [password, setPassword] = useState(INITIAL_STATE.password);

  const { t } = useTranslation();

  const inputChangeHandler = e => {
    const { name, value } = e.currentTarget;

    switch (name) {
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;

      default:
        break;
    }
  };

  const submitHandler = e => {
    e.preventDefault();
    const data = { username, password };

    _auth(data);
  };

  const _auth = async data => {
    try {
      const res = await getAuthenticated(data);
      if (res?.status === statusCodes.HTTP_200_OK) {
        localStorage.setItem('tokenPreap', res.data.token);
        localStorage.setItem('usernamePreap', res.data.login);

        setUsername(INITIAL_STATE.username);
        setPassword(INITIAL_STATE.password);

        handleAuth(true);
      }
    } catch (err) {
      let errMessage = '';
      if (err?.response?.status === statusCodes.HTTP_400_BAD_REQUEST) {
        errMessage = t('errors.unauthorized', { message: err.message });
      } else {
        errMessage = t('errors.errorMessage', { message: err.message });
      }
      createErrorNotification(errMessage);
      console.log(err.message);
    }
  };

  return (
    <>
      <Container id="AuthPage" fluid>
        <h1 className="auth-heading">{t('tip.loginFormTitle')}</h1>
        <Form className="auth-form" autoComplete="off" onSubmit={submitHandler}>
          <Form.FloatingLabel
            className="mb-4"
            label={t('tip.username')}
            controlId="formBasicEmail"
          >
            <Form.Control
              required
              type="text"
              name="username"
              value={username}
              onChange={inputChangeHandler}
              placeholder={t('tip.username')}
            />
          </Form.FloatingLabel>

          <Form.FloatingLabel
            className="mb-4"
            controlId="formBasicPassword"
            label={t('tip.password')}
          >
            <Form.Control
              required
              type="password"
              name="password"
              value={password}
              onChange={inputChangeHandler}
              placeholder={t('tip.password')}
            />
          </Form.FloatingLabel>

          <Button variant="primary" type="submit">
            {t('actions.login')}
          </Button>
        </Form>
      </Container>
    </>
  );
};

AuthView.propTypes = {
  handleAuth: PropTypes.func.isRequired,
};

export default AuthView;
