import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import CustomContentContainer from '../CustomContentContainer';
import { createErrorNotification } from '../../utils';
import { getYandexLink } from '../../services/api_requests';
import { lngs } from '../../helpers/options';
import Book from '../../assets/icons/Book';
import logo from '../../assets/pre_logo_rus.png';
import logoEn from '../../assets/pre_logo_eng.png';
import './Header.css';

const Header = ({ auth, handleAuth }) => {
  const [language, setLanguage] = useState('ru');

  const { t, i18n } = useTranslation();

  const handleLanguage = e => {
    const { value } = e.target;

    setLanguage(value);
    i18n.changeLanguage(value);
    // localStorage.setItem('lngPreap', value);
  };

  const download = async () => {
    try {
      const response = await getYandexLink();
      const data = await response.json();

      let tempLink = document.createElement('a');
      tempLink.href = data.href;
      tempLink.target = '_blank';
      tempLink.rel = 'noopener noreferrer';
      tempLink.click();
    } catch (err) {
      const errMessage = t('errors.failedDownload', { message: err.message });

      createErrorNotification(errMessage);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('tokenPreap');
    localStorage.removeItem('loginPreap');

    handleAuth(false);
  };

  return (
    <CustomContentContainer type="header_content">
      <Container fluid id="header-block">
        <div className="logo__wrapper">
          <img
            src={language === 'en' ? logoEn : logo}
            alt="logo"
            width="300"
            height="47"
          />
        </div>

        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id={`tooltip-manual`}>
              {t('actions.downloadManual')}
            </Tooltip>
          }
        >
          <Button
            className={'manual-btn'}
            variant="outline-secondary"
            onClick={download}
          >
            <Book />
          </Button>
        </OverlayTrigger>
        {auth && (
          <Button
            className={'logout-btn'}
            variant="outline-secondary"
            onClick={handleLogout}
          >
            {t('actions.logout')}
          </Button>
        )}
      </Container>
    </CustomContentContainer>
  );
};

Header.propTypes = {
  auth: PropTypes.bool.isRequired,
  handleAuth: PropTypes.func.isRequired,
};

export default Header;
