import PropTypes from 'prop-types';
import './CustomContentContainer.css';

const CustomContentContainer = ({ children, type }) => {
  return <div className={`CustomContentContainer ${type}`}>{children}</div>;
};

CustomContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
};

export default CustomContentContainer;
