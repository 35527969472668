import { useLocation, Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

const PublicRoute = ({ redirectTo = '/', children, auth }) => {
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || redirectTo;

  return auth ? (
    <Navigate to={fromPage} state={{ from: location }} />
  ) : children ? (
    children
  ) : (
    <Outlet />
  );
};

PublicRoute.propTypes = {
  redirectTo: PropTypes.string,
  children: PropTypes.node,
  auth: PropTypes.bool.isRequired,
};

export default PublicRoute;
