export default function Compress() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.6715 1L14.5347 8.13678V4.21357H12.66V11.3372H19.7836V9.46245H15.8603L22.9971 2.32561L21.6715 1Z" />
      <path d="M4.21364 13V14.8747H8.13685L1 22.0116L2.32561 23.3372L9.46252 16.2003V20.1236H11.3372V13H4.21364Z" />
    </svg>
  );
}
