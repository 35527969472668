import { useMemo, useState, useEffect } from 'react';
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { WindowedMenuList } from 'react-windowed-select';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import PlainTable from '../PlainTable';
import CustomSelectOption from '../CustomSelectOption';
import Copy from '../../assets/icons/Copy';
import Trash from '../../assets/icons/Trash';
import './ServicesTable.css';

const ServicesTable = ({
  data,
  options,
  onRemove,
  addService,
  declineOptions,
  onChangeService,
  requestIsAnalyzed,
  onChangeServiceName,
  onDeclineAllServices,
  onApproveAllServices,
  onChangeDeclineReason,
}) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        id: 'copyName',
        style: {
          width: '4%',
        },
        Cell: ({ row: { original } }) => (
          <CopyToClipboard text={original.name}>
            <Button
              size="sm"
              variant="outline-light"
              className="action-icon action-icon__copy mx-auto"
              title={t('actions.copyName', { name: t('services.name') })}
            >
              <Copy />
            </Button>
          </CopyToClipboard>
        ),
      },
      {
        Header: t('services.serviceName'),
        accessor: 'name',
        style: {
          width: '35%',
        },
        Cell: ({
          value,
          row: { index, original },
          column: { id: columnName },
        }) => {
          return (
            <div title={value}>
              <CreatableSelect
                // menuIsOpen
                id={'Service-' + original.service_id}
                value={{ value, label: value }}
                options={options}
                openMenuOnFocus
                placeholder={null}
                maxMenuHeight={210}
                loadingMessage={t('tip.loading')}
                formatCreateLabel={userInput =>
                  t('tip.createLabel', { userInput })
                }
                components={{
                  MenuList: WindowedMenuList,
                  Option: CustomSelectOption,
                }}
                onChange={selected =>
                  onChangeServiceName({ selected, index, columnName })
                }
                filterOption={createFilter({ ignoreAccents: false })}
                classNamePrefix="custom-select"
              />
            </div>
          );
        },
      },
      {
        accessor: 'service_id',
        style: {
          width: '5%',
        },
        Cell: ({ value }) => (
          <Button
            size="sm"
            variant="outline-light"
            title={t('services.serviceDelete')}
            className="action-icon action-icon__trash mx-auto"
            onClick={() => {
              onRemove(value, 'services');
            }}
          >
            <Trash />
          </Button>
        ),
      },
      {
        Header: t('services.toothNum'),
        accessor: 'tooth_num',
        style: { width: '6%' },
        Cell: ({
          value: initialValue,
          row: { index, original },
          column: { id: columnName },
        }) => {
          const [value, setValue] = useState(initialValue);

          useEffect(() => {
            setValue(initialValue);
          }, [initialValue]);

          const onChange = e => {
            const re = /^[-:+\d(), ]+$/;
            if (re.test(e.target.value) || e.target.value.length === 0) {
              setValue(e.target.value);
            }
          };

          const onBlur = () => {
            if (value !== initialValue) {
              onChangeService({ columnName, index, value });
            }
          };

          if (original.is_tooth) {
            return (
              <input
                id={'ToothNum-' + index}
                value={value || ''}
                onBlur={onBlur}
                onChange={onChange}
              />
            );
          } else {
            return '';
          }
        },
      },
      {
        Header: t('services.serviceCount'),
        accessor: 'count',
        style: { width: '6%' },
        Cell: ({
          value: initialValue,
          row: { index },
          column: { id: columnName },
        }) => {
          const [value, setValue] = useState(initialValue);

          useEffect(() => {
            setValue(initialValue);
          }, [initialValue]);

          const onChange = e => {
            setValue(e.target.value);
          };

          const onBlur = () => {
            let newValue = parseInt(value);

            if (isNaN(newValue) || newValue < 1) {
              newValue = 1;
            }
            newValue = newValue.toString();

            setValue(newValue);
            if (newValue !== initialValue) {
              onChangeService({ columnName, index, value: newValue });
            }
          };

          return (
            <input
              id={'Count-' + index}
              min="1"
              type="number"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              onWheel={e => e.target.blur()}
              onKeyPress={e => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          );
        },
      },
      {
        Header: t('services.approveResult'),
        accessor: 'approve_result',
        style: { width: '7%' },
        Cell: ({
          value: initialValue,
          row: { index },
          column: { id: columnName },
        }) => {
          const [value, setValue] = useState(initialValue);

          useEffect(() => {
            setValue(initialValue);
          }, [initialValue]);

          const onChange = e => {
            setValue(e.target.checked);

            setTimeout(() => {
              onChangeService({ columnName, index, value: e.target.checked });
            }, 160);
          };

          if (requestIsAnalyzed) {
            return (
              <Container>
                <Form.Check
                  id={'Checkbox-' + index}
                  className="mx-auto"
                  inline
                  label={null}
                  type="switch"
                  checked={value}
                  onChange={onChange}
                />
              </Container>
            );
          } else {
            return '';
          }
        },
      },
      {
        Header: t('services.declineReason'),
        accessor: 'decline_reason',
        style: { width: '18%' },
        Cell: ({
          value,
          row: { index, original },
          column: { id: columnName },
        }) => {
          if (requestIsAnalyzed && !original.approve_result) {
            return (
              <div title={original?.criteria_text ?? ''}>
                <CreatableSelect
                  id={'DeclineReason-' + index}
                  classNamePrefix="custom-select"
                  options={declineOptions}
                  value={value ? { value, label: value } : null}
                  loadingMessage={t('tip.loading')}
                  placeholder={t('tip.optionNotSelected')}
                  formatCreateLabel={userInput =>
                    t('tip.createLabel', { userInput })
                  }
                  onChange={selected =>
                    onChangeDeclineReason({ selected, index, columnName })
                  }
                  filterOption={createFilter({ ignoreAccents: false })}
                />
              </div>
            );
          } else {
            return '';
          }
        },
      },
      {
        Header: t('services.comment'),
        accessor: 'comment',
        style: { width: '20%' },
        Cell: ({
          value: initialValue,
          row: { index },
          column: { id: columnName },
        }) => {
          const [value, setValue] = useState(initialValue);

          useEffect(() => {
            setValue(initialValue);
          }, [initialValue]);

          const onChange = e => {
            setValue(e.target.value);
          };

          const onBlur = () => {
            if (value !== initialValue) {
              onChangeService({ columnName, index, value });
            }
          };

          return (
            <textarea
              id={'Comment-' + index}
              className="textbox"
              value={value || ''}
              onBlur={onBlur}
              onChange={onChange}
            ></textarea>
          );
        },
      },
    ],
    [
      declineOptions,
      onChangeDeclineReason,
      onChangeService,
      onChangeServiceName,
      onRemove,
      options,
      requestIsAnalyzed,
      t,
    ],
  );

  return (
    <Container id="ServicesTable" fluid>
      <PlainTable data={data} columns={columns} />
      <Row className="btn-row">
        <Col md="4">
          <Button variant="outline-primary" size="sm" onClick={addService}>
            {t('actions.addService')}
          </Button>
        </Col>
        <Col md="7">
          <Button
            size="sm"
            variant="outline-primary"
            onClick={onApproveAllServices}
          >
            {t('actions.approveAll')}
          </Button>
          <Button
            size="sm"
            variant="outline-danger"
            className="result-btn-decline"
            onClick={onDeclineAllServices}
          >
            {t('actions.rejectAll')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

ServicesTable.propTypes = {
  onRemove: PropTypes.func.isRequired,
  addService: PropTypes.func.isRequired,
  onChangeService: PropTypes.func.isRequired,
  requestIsAnalyzed: PropTypes.bool.isRequired,
  onChangeServiceName: PropTypes.func.isRequired,
  onApproveAllServices: PropTypes.func.isRequired,
  onDeclineAllServices: PropTypes.func.isRequired,
  onChangeDeclineReason: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  declineOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ServicesTable;
