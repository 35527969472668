import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Excel from '../../assets/icons/Excel';
import Email from '../../assets/icons/Email';
import Copy from '../../assets/icons/Copy';
import './UsefulBlock.css';

const UsefulBlock = ({
  services,
  diagnoses,
  onDownload,
  countsBefore,
  requestIsAnalyzed,
}) => {
  const [resultText, setResultText] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    if (!requestIsAnalyzed) {
      return;
    }

    const approvedList = [];
    const declinedList = [];
    const partlyApprovedList = [];
    const diagnosesList = diagnoses
      .filter(el => !el.exacerbation)
      .map(el => el.mkb_code);

    const count = t('approvedText.count');
    const reason = t('approvedText.reason');
    const noReason = t('tip.optionNotSelected');

    services.forEach(service => {
      const targetCount = countsBefore.find(el => el[service.service_id]);
      let equality = null;

      if (targetCount) {
        equality = parseInt(service.count) === targetCount[service.service_id];
      }

      const countCheck = parseInt(service.count) > 1;

      const toothCheck =
        service.tooth_num !== '' &&
        service.tooth_num !== '0' &&
        service.tooth_num !== '-1';

      if (service.approve_result && targetCount && !equality) {
        partlyApprovedList.push(
          service.name +
            (toothCheck ? ' ' + service.tooth_num : '') +
            (countCheck ? ' ' + count + ' ' + service.count : ''),
        );
      }

      if (
        (service.approve_result && equality) ||
        (service.approve_result && !targetCount)
      ) {
        approvedList.push(
          service.name +
            (toothCheck ? ' ' + service.tooth_num : '') +
            (countCheck ? ' ' + count + ' ' + service.count : ''),
        );
      }

      if (!service.approve_result) {
        declinedList.push(
          service.name +
            ', ' +
            reason +
            ': ' +
            (service.decline_reason || noReason),
        );
      }
    });

    const introApproved = t('approvedText.introApproved', {
      services: approvedList.join('; '),
    });
    const introNotApproved = t('approvedText.introNotApproved', {
      services: declinedList.join('; '),
    });
    const introPartiallyApproved = t('approvedText.introPartiallyApproved', {
      services: partlyApprovedList.join('; '),
    });
    const introDiagnoses = t('approvedText.introDiagnoses', {
      diagnoses: diagnosesList.join(', '),
    });

    const finalMessage =
      (diagnosesList.length > 0 ? introDiagnoses : '') +
      (approvedList.length > 0 ? ' ' + introApproved : '') +
      (partlyApprovedList.length > 0 ? '\n' + introPartiallyApproved : '') +
      (declinedList.length > 0 ? '\n' + introNotApproved : '');

    setResultText(finalMessage);
  }, [countsBefore, diagnoses, requestIsAnalyzed, services, t]);

  const handleSendEmail = () => {
    const emailSubject = t('approvedText.emailSubject');
    const emailBody = t('approvedText.emailBody');

    window.location.href =
      `mailto:?subject=${emailSubject}&body=${emailBody} ` + resultText;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(resultText);
  };

  return (
    <div className="UsefulBlock">
      <OverlayTrigger
        placement="top-start"
        overlay={
          <Tooltip id="tooltip-excel">{t('tip.downloadExcelFile')}</Tooltip>
        }
      >
        <Button
          className="useful-btn"
          variant="outline-secondary"
          disabled={!requestIsAnalyzed}
          onClick={onDownload}
        >
          <Excel />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top-start"
        overlay={<Tooltip id="tooltip-email">{t('tip.sendEmail')}</Tooltip>}
      >
        <Button
          className="useful-btn"
          variant="outline-secondary"
          disabled={!requestIsAnalyzed}
          onClick={handleSendEmail}
        >
          <Email />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top-start"
        overlay={
          <Tooltip id="tooltip-copy">{t('tip.copyToClipboard')}</Tooltip>
        }
      >
        <Button
          className="useful-btn"
          variant="outline-secondary"
          disabled={!requestIsAnalyzed}
          onClick={handleCopy}
        >
          <Copy />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

UsefulBlock.propTypes = {
  requestIsAnalyzed: PropTypes.bool.isRequired,
  onDownload: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(PropTypes.object),
  diagnoses: PropTypes.arrayOf(PropTypes.object),
  countsBefore: PropTypes.arrayOf(PropTypes.object),
};

export default UsefulBlock;
