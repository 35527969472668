import axios, { isCancel } from '../axios';

export const getAuthenticated = async data => {
  try {
    const res = await axios.post('auth', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

// YandexDisc
export const getYandexLink = async () => {
  try {
    const res = await fetch(
      `https://cloud-api.yandex.net/v1/disk/public/resources/download?public_key=https%3A%2F%2Fdisk.yandex.ru%2Fi%2FhEQHCACSsYE2WQ`,
      {
        method: 'GET',
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getOrganization = async abortCtrl => {
  try {
    const res = await axios.get('organization', { signal: abortCtrl.signal });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const getServicesOptions = async (language, { signal }) => {
  try {
    const res = await axios.get(`services/${language}`, {
      signal,
    });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const getDiagnosesOptions = async (language, { signal }) => {
  try {
    const res = await axios.get(`diagnosis/${language}`, {
      signal,
    });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const getDeclineOptions = async (language, { signal }) => {
  try {
    const res = await axios.get(`defects/${language}`, {
      signal,
    });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const getRequestClassified = async data => {
  try {
    const res = await axios.post('request_analyze', data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getDataAnalyzed = async data => {
  try {
    const res = await axios.post('research', { data });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getApprovedTask = async data => {
  try {
    const res = await axios.post('approve', data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getNormativeActs = async code => {
  try {
    const res = await axios.get(`diagnosis/${code}/normative_act`);
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const downloadExcel = async data => {
  try {
    const res = await axios.post('download', data, {
      responseType: 'blob',
    });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};
