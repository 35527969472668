import { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Header from './components/Header';
import PublicRoute from './components/routes/PublicRoute';
import PrivateRoute from './components/routes/PrivateRoute';
import AuthView from './views/AuthView';
import MainView from './views/MainView';
import axios from './axios';

function App() {
  const [auth, setAuth] = useState(false);
  const [checking, setChecking] = useState(true);

  const [urlText, setUrlText] = useState('');
  const [urlId, setUrlId] = useState('1');
  const [urlCheckable, setUrlCheckable] = useState('1');

  const location = useLocation();
  const token = localStorage.getItem('tokenPreap');

  axios.defaults.headers.common['Authorization'] = token;

  useEffect(() => {
    if (token) {
      setAuth(true);
    }

    setChecking(false);
  }, [token]);

  useEffect(() => {
    const string = decodeURI(location.search);

    if (string.trim()) {
      const arr = string.split('/?');

      const refactoredArr = arr.map((el, i) => {
        if (i === 0) {
          return el.slice(1);
        } else {
          return el;
        }
      });

      refactoredArr.forEach(el => {
        if (el.includes('text')) {
          setUrlText(el.slice(5).split('_').join(' '));
        } else if (el.includes('id')) {
          setUrlId(el.slice(3));
        } else if (el.includes('checkable')) {
          setUrlCheckable(el.slice(-1));
        } else {
          return null;
        }
      });
    } else {
      setUrlText('');
      setUrlId('');
      setUrlCheckable('1');
    }
  }, [location.search]);

  return (
    <>
      {!checking && (
        <>
          <Header auth={auth} handleAuth={setAuth} />
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute auth={auth}>
                  <MainView
                    urlText={urlText}
                    urlId={urlId}
                    urlCheckable={urlCheckable}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/auth"
              element={
                <PublicRoute auth={auth}>
                  <AuthView handleAuth={setAuth} />
                </PublicRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </>
      )}

      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;
