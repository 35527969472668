import { Container } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';
import './LoaderWrapper.css';

const LoaderWrapper = () => {
  return (
    <Container fluid>
      <TailSpin
        width={200}
        height={200}
        color="var(--color-white)"
        wrapperClass="TailSpin-wrapper"
      />
    </Container>
  );
};

export default LoaderWrapper;
