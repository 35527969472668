import { Oval } from 'react-loader-spinner';
import './OverLayLoader.css';

const OverLayLoader = () => {
  return (
    <div className="overlay-loader__wrapper">
      <Oval
        type="Oval"
        color="var(--color-primary-blue)"
        secondaryColor="var(--color-primary-blue)"
        height={80}
        width={80}
        ariaLabel="loading-indicator"
      />
    </div>
  );
};

export default OverLayLoader;
