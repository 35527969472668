import PropTypes from 'prop-types';
import './BlockHeader.css';

const BlockHeader = ({ heading, visibleTables }) => {
  return (
    <div
      className="BlockHeader"
      style={visibleTables ? { textAlign: 'end' } : {}}
    >
      <span>{heading}</span>
    </div>
  );
};

BlockHeader.propTypes = {
  visibleTables: PropTypes.bool,
  heading: PropTypes.string.isRequired,
};

export default BlockHeader;
