export default function Done() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1.25rem"
      viewBox="0 0 24 24"
    >
      <path d="M8.64708 21.3199L1 12.8464L3.62489 10.4779L8.55428 15.9419L20.2967 2L23 4.27689L8.64708 21.3199Z" />
    </svg>
  );
}
