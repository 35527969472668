import PropTypes from 'prop-types';

const NormativeActItem = ({ act }) => {
  return (
    <>
      {(act.normative_act || act.designation_of_the_order) && (
        <>
          {act.designation_of_the_order && act.link_to_order && (
            <a
              title={act.name_of_order}
              href={act.link_to_order}
              target="_blank"
              rel="noopener noreferrer"
            >
              {act.designation_of_the_order}
            </a>
          )}
          {act.designation_of_the_order && !act.link_to_order && (
            <span>{act.designation_of_the_order}</span>
          )}
          {act.normative_act && act.designation_of_the_order && <span>/</span>}
          {act.normative_act && act.normative_act_link && (
            <a
              title={act.normative_act_full_text}
              href={act.normative_act_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {act.normative_act}
            </a>
          )}
          {act.normative_act && !act.normative_act_link && (
            <span>{act.normative_act}</span>
          )}
        </>
      )}
    </>
  );
};

NormativeActItem.propTypes = {
  act: PropTypes.object,
};

export default NormativeActItem;
