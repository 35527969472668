import { useState, useEffect, useCallback, useRef } from 'react';
import { nanoid } from 'nanoid';
import {
  Container,
  Button,
  Row,
  Col,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import BlockHeader from '../components/BlockHeader';
import UsefulBlock from '../components/UsefulBlock';
import ServicesTable from '../components/ServicesTable';
import OverLayLoader from '../components/OverLayLoader';
import LoaderWrapper from '../components/LoaderWrapper';
import DiagnosisTable from '../components/DiagnosisTable';
import CustomContentContainer from '../components/CustomContentContainer';
import Bed from '../assets/icons/Bed';
import Done from '../assets/icons/Done';
import {
  downloadExcel,
  getApprovedTask,
  getDataAnalyzed,
  getServicesOptions,
  getOrganization,
  getDiagnosesOptions,
  getDeclineOptions,
  getRequestClassified,
  getNormativeActs,
} from '../services/api_requests';
import { createErrorNotification, saveFile } from '../utils';
import './styles/MainView.css';

const MainView = ({ urlText, urlId, urlCheckable }) => {
  const [servicesOptions, setServicesOptions] = useState({ ru: [], en: [] });
  const [declineOptions, setDeclineOptions] = useState({ ru: [], en: [] });
  const [diagnosesOptions, setDiagnosesOptions] = useState({ ru: [], en: [] });
  const [servicesTableData, setServicesTableData] = useState([]);
  const [diagnosisTableData, setDiagnosisTableData] = useState([]);

  const [organization, setOrganization] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [caseType, setCaseType] = useState('usual');
  const [requestText, setRequestText] = useState('');
  const [checkable, setCheckable] = useState('1');
  const [policyNumber, setPolicyNumber] = useState('1');

  const [requestIsClassified, setRequestIsClassified] = useState(false);
  const [requestIsAnalyzed, setRequestIsAnalyzed] = useState(false);
  const [requestIsApproved, setRequestIsApproved] = useState(false);

  const [visibleTables, setVisibleTables] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);
  const [isLoadingMkbDict, setIsLoadingMkbDict] = useState(false);
  const [countsBefore, setCountsBefore] = useState([]);

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const abortCtrlRef = useRef(null);

  const username = localStorage.getItem('usernamePreap');

  const handleClassified = async () => {
    try {
      setIsLoadingLocal(true);
      setRequestIsClassified(false);
      setRequestIsAnalyzed(false);
      setRequestIsApproved(false);
      setCountsBefore([]);

      let diagnoses = [];
      let services = [];

      const data = {
        login: username,
        organization,
        text: requestText,
        language,
        policy_number: policyNumber,
      };

      const { data: responseData } = await getRequestClassified(data);

      if (requestText.length > 0) {
        diagnoses = responseData.data.diagnosis;
        services = responseData.data.services;
      }

      diagnoses.forEach(diagnose => {
        diagnose.exacerbation = false;
        diagnose.id = nanoid();
      });

      services.forEach(service => {
        if (service.count === '') {
          service.count = '1';
        }
        service.comment = '';
        service.decline_reason = '';
        service.approve_result = false;
        service.initial_approved = false;
      });

      setDiagnosisTableData(diagnoses);
      setServicesTableData(services);
      setRequestIsClassified(true);
      setVisibleTables(true);
      setSessionId(responseData.session_id);
      setCaseType(() =>
        responseData.data.caseType ? responseData.data.caseType : 'usual',
      );
    } catch (error) {
      let message = '';

      if (error?.response?.status) {
        message = t('errors.errorMessage', { message: error.message });
      } else {
        message = t('errors.common');
      }

      createErrorNotification(message);
      console.error(error.message);
    } finally {
      setIsLoadingLocal(false);
    }
  };

  const getOrganizationData = useCallback(async () => {
    if (abortCtrlRef.current) {
      abortCtrlRef.current.abort();
    }

    abortCtrlRef.current = new AbortController();
    try {
      const orgData = await getOrganization(abortCtrlRef.current);

      if (
        orgData?.data?.organization === null ||
        orgData?.data?.organization === ''
      ) {
        localStorage.removeItem('tokenPreap');
      }

      setOrganization(orgData?.data?.organization);
    } catch (error) {
      let message = '';

      if (error?.response?.status) {
        message = t('errors.errorMessage', { message: error.message });
      } else {
        message = t('errors.common');
      }

      createErrorNotification(message);
      console.error(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (urlText && organization) {
      handleClassified();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, urlText]);

  useEffect(() => {
    const abortCtrl = new AbortController();

    const fetchData = async () => {
      setIsLoading(true);
      setIsLoadingMkbDict(true);
      setVisibleTables(false);
      setRequestText(urlText);
      setPolicyNumber(urlId);
      setCheckable(urlCheckable);

      await getOrganizationData();

      await getServicesOptions(language, abortCtrl)
        .then(res => {
          if (res?.data?.data) {
            setServicesOptions(prev => ({
              ...prev,
              [language]: res.data.data,
            }));
            setIsLoading(false);

            Promise.all([
              getDeclineOptions(language, abortCtrl),
              getDiagnosesOptions(language, abortCtrl),
            ])
              .then(res => {
                setDeclineOptions(prev => ({
                  ...prev,
                  [language]: res[0]?.data?.data,
                }));
                setDiagnosesOptions(prev => ({
                  ...prev,
                  [language]: res[1]?.data?.data,
                }));
                setIsLoadingMkbDict(false);
              })
              .catch(error => {
                let message = '';

                if (error?.response?.status) {
                  message = t('errors.errorMessage', {
                    message: error.message,
                  });
                } else {
                  message = t('errors.common');
                }

                createErrorNotification(message);
                console.error(error.message);

                setIsLoadingMkbDict(false);
              });
          }
        })
        .catch(error => {
          let message = '';

          if (error?.response?.status) {
            message = t('errors.errorMessage', { message: error.message });
          } else {
            message = t('errors.common');
          }

          createErrorNotification(message);
          console.error(error.message);

          setIsLoading(false);
          setIsLoadingMkbDict(true);
        });
    };

    fetchData();

    return () => {
      abortCtrl.abort();
    };
  }, [getOrganizationData, language, t, urlCheckable, urlId, urlText]);

  const handleAnalyze = async () => {
    const nameChecked = diagnosisTableData.find(el => el.diagnosis_name !== '');
    if (diagnosisTableData.length === 0 || !nameChecked) {
      const message = t('errors.noDiagnosis');
      toast.warn(message, {
        position: 'top-right',
      });
      return;
    }

    try {
      setIsLoadingLocal(true);
      const newDiagnosis = handleRemoveEmptyDiagnosis();
      const newServices = handleRemoveEmptyServices();

      handleSaveCounters(newServices);

      const data = {
        diagnosis: newDiagnosis,
        services: newServices,
        historical_services: [],
        language,
        checkable,
        organization,
        case_type: caseType,
        session_id: sessionId,
        policy_number: policyNumber,
      };

      const { data: analyzedData } = await getDataAnalyzed(data);

      const newData = analyzedData.data.map(el => ({
        ...el,
        initial_approved: el.approve_result,
        comment: el?.comment ?? '',
      }));

      setServicesTableData(newData);
      setRequestIsAnalyzed(true);
    } catch (error) {
      let message = '';
      if (error?.response?.status) {
        message = t('errors.errorAnalysis', { message: error.message });
      } else {
        message = t('errors.common');
      }
      createErrorNotification(message);
      console.error(error.message);
    } finally {
      setIsLoadingLocal(false);
    }
  };

  const handleSaveCounters = services => {
    const counts = services.map(({ service_id, count }) => ({
      [service_id]: parseInt(count),
    }));
    setCountsBefore(counts);
  };

  const handleRemoveEmptyDiagnosis = () => {
    const filteredDiagnosisData = diagnosisTableData.filter(
      el => el.diagnosis_name,
    );
    setDiagnosisTableData(filteredDiagnosisData);
    return filteredDiagnosisData;
  };

  const handleRemoveEmptyServices = () => {
    const filteredServicesTableData = servicesTableData.filter(el => el.name);
    setServicesTableData(filteredServicesTableData);
    return filteredServicesTableData;
  };

  const handleApproveTask = async () => {
    try {
      setIsLoadingLocal(true);
      const newDiagnosis = handleRemoveEmptyDiagnosis();
      const newServices = handleRemoveEmptyServices();

      const hasNotChosen = newServices.find(
        el => !el.approve_result && !el.decline_reason,
      );

      if (hasNotChosen) {
        toast.error(t('errors.noReason'), {
          position: 'top-right',
        });
        return;
      }

      const data = {
        diagnosis: newDiagnosis,
        services: newServices,
        session_id: sessionId,
      };

      await getApprovedTask(data);

      setRequestIsApproved(true);
      setCaseType('usual');
    } catch (error) {
      let message = '';

      if (error?.response?.status) {
        message = t('errors.errorApprove', { message: error.message });
      } else {
        message = t('errors.common');
      }

      createErrorNotification(message);
      console.error(error.message);
    } finally {
      setIsLoadingLocal(false);
    }
  };

  const handleChangeRequestText = e => {
    setRequestText(e.target.value);
  };

  const handleToggleCaseType = e => {
    if (e.target.value !== caseType) {
      setCaseType(e.target.value);
    } else {
      setCaseType('usual');
    }
  };

  const handleChangeDiagnosisName = (selected, rowIndex) => {
    getDataByDiagnosisName(selected.mkb_code).then(res => {
      setDiagnosisTableData(prev =>
        prev.map((row, index) => {
          return index === rowIndex
            ? {
                ...row,
                mkb_code: selected.mkb_code,
                diagnosis_name: selected.label,
                mkb_diagnosis: selected.label
                  .replace(selected.mkb_code, '')
                  .trim(),
                normative_act: res?.data || [],
              }
            : row;
        }),
      );
    });
  };

  const handleChangeServiceName = ({ selected, index, columnName }) => {
    const prevService = servicesTableData[index];

    if (prevService[columnName] !== '' && selected.__isNew__) {
      const message = t('errors.createCustomService');
      toast.warning(message, {
        position: 'top-right',
        autoClose: 5000,
      });
      return;
    } else {
      setServicesTableData(prev =>
        prev.map((row, i) => {
          return i === index
            ? {
                ...row,
                [columnName]: selected.value,
                code_sub: selected.code_sub ?? '',
              }
            : row;
        }),
      );
    }
  };

  const handleChangeDeclineReason = ({ selected, index, columnName }) =>
    setServicesTableData(prev =>
      prev.map((row, i) => {
        return i === index
          ? {
              ...row,
              [columnName]: selected.value,
            }
          : row;
      }),
    );

  const handleChangeServiceRow = ({ columnName, index, value }) => {
    setServicesTableData(prev =>
      prev.map((row, i) =>
        index === i
          ? {
              ...row,
              [columnName]: value,
              decline_reason:
                columnName === 'approve_result' ? '' : row.decline_reason,
              corrected: row.initial_approved !== value,
            }
          : row,
      ),
    );
  };

  const handleChangeDiagnosisRow = ({ columnName, index, value }) => {
    setDiagnosisTableData(prev =>
      prev.map((row, i) =>
        index === i ? { ...row, [columnName]: value } : row,
      ),
    );
  };

  const getDataByDiagnosisName = async code => {
    try {
      const { data } = await getNormativeActs(code);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  };

  const addDiagnosisHandler = () => {
    const id = nanoid();
    const newDiagnosis = {
      id,
      diagnosis_name: '',
      exacerbation: false,
      normative_act: [],
    };

    setDiagnosisTableData(prev => [...prev, newDiagnosis]);

    setTimeout(() => {
      const parentElement = document.getElementById(`${'Diagnosis-' + id}`);
      const selectInput = parentElement.querySelector('input');

      selectInput.focus();
    }, 100);
  };

  const addServiceHandler = () => {
    const id = nanoid();

    const newService = {
      service_id: id,
      name: '',
      approve_result: false,
      decline_reason: '',
      tooth_num: '',
      count: '1',
      is_tooth: false,
      initial_approved: true,
      corrected: false,
      comment: '',
      rules_worked: '',
    };

    setServicesTableData(prev => [...prev, newService]);

    setTimeout(() => {
      const parentElement = document.getElementById(`${'Service-' + id}`);
      const selectInput = parentElement.querySelector('input');

      selectInput.focus();
    }, 100);
  };

  const handleRemoveRow = (id, name) => {
    switch (name) {
      case 'diagnoses':
        setDiagnosisTableData(prev => prev.filter(row => row.id !== id));
        break;

      case 'services':
        setServicesTableData(prev => prev.filter(row => row.service_id !== id));
        break;

      default:
        break;
    }
  };

  const handleApproveAllServices = () => {
    setServicesTableData(prev =>
      prev.map(row => ({
        ...row,
        approve_result: true,
        decline_reason: '',
        corrected: row.initial_approved !== true,
      })),
    );
  };

  const handleDeclineAllServices = () => {
    setServicesTableData(prev =>
      prev.map(row => ({
        ...row,
        approve_result: false,
        decline_reason: '',
        corrected: row.initial_approved !== false,
      })),
    );
  };

  const handleExcelDownload = async () => {
    try {
      setIsLoadingLocal(true);
      const newDiagnosis = handleRemoveEmptyDiagnosis();
      const newServices = handleRemoveEmptyServices();

      handleSaveCounters(newServices);

      const data = {
        data: {
          diagnosis: newDiagnosis,
          services: newServices,
          organization,
          session_id: sessionId,
        },
      };

      const response = await downloadExcel(data);

      if (response) {
        const fileName = t('tip.approvalResult') + '.xlsx';
        saveFile(response.data, fileName);
      }
    } catch (error) {
      let message = '';

      if (error?.response?.status) {
        message = t('errors.failedDownload', { message: error.message });
      } else {
        message = t('errors.common');
      }

      createErrorNotification(message);
      console.error(error.message);
    } finally {
      setIsLoadingLocal(false);
    }
  };

  const textBlockSize = visibleTables ? 4 : 12;

  return (
    <Container
      id="MainPage"
      fluid
      className={`text-light ${caseType === 'preoperative' && 'green-bg'}`}
    >
      <CustomContentContainer type="main_content">
        {/* mainBlock */}
        {isLoading ? (
          <LoaderWrapper />
        ) : (
          <Container fluid id="main-block">
            <Row className="textarea-container">
              {/* textBlock */}
              <Col md={textBlockSize} className={'column text-block'}>
                <BlockHeader
                  heading={t('requestField.heading')}
                  visibleTables={visibleTables}
                />
                <InputGroup>
                  <InputGroup.Text>{t('requestField.text')}</InputGroup.Text>
                  <FormControl
                    as="textarea"
                    className="text-box"
                    value={requestText}
                    onChange={handleChangeRequestText}
                  />
                </InputGroup>
              </Col>
              {/* diagnosisTableBlock */}
              {visibleTables && (
                <Col
                  md="8"
                  className={
                    'column bg-light expandable-wrapper table__bordered'
                  }
                >
                  <BlockHeader heading={t('diagnoses.heading')} />
                  <DiagnosisTable
                    data={diagnosisTableData}
                    onRemove={handleRemoveRow}
                    isLoading={isLoadingMkbDict}
                    addDiagnosis={addDiagnosisHandler}
                    options={diagnosesOptions[language]}
                    onChangeDiagnosis={handleChangeDiagnosisRow}
                    onChangeDiagnosisName={handleChangeDiagnosisName}
                  />
                </Col>
              )}
            </Row>
            <Row className="button-block">
              <Row className="default-block">
                <Col md={textBlockSize} className="column">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        id="tooltip-disabled"
                        style={
                          requestIsAnalyzed && !requestIsApproved
                            ? {}
                            : { display: 'none' }
                        }
                      >
                        {t('tip.approveRequest')}
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <Button
                        className="action-button"
                        variant="primary"
                        disabled={requestIsAnalyzed && !requestIsApproved}
                        style={
                          requestIsAnalyzed && !requestIsApproved
                            ? { pointerEvents: 'none' }
                            : {}
                        }
                        onClick={handleClassified}
                      >
                        {t('actions.recognizeRequest')}
                      </Button>
                    </span>
                  </OverlayTrigger>
                </Col>
                {visibleTables && (
                  <Col md={8}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip className="QQQQ" id="tooltip-preoperative">
                          {t('tip.preoperative')}
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block">
                        <Button
                          className="action-button preoperative-btn"
                          variant="outline-success"
                          active={caseType === 'preoperative'}
                          value="preoperative"
                          onClick={handleToggleCaseType}
                        >
                          <Bed />
                        </Button>
                      </span>
                    </OverlayTrigger>
                  </Col>
                )}
              </Row>
            </Row>
            {/* servicesTableBlock */}
            {visibleTables && (
              <Row className="table-container">
                <Container
                  fluid
                  className="bg-light tables-block table__bordered"
                >
                  <BlockHeader heading={t('services.heading')} />
                  <Row className="tables-block">
                    <Col md="12" className="column">
                      <Container className="list" fluid>
                        <ServicesTable
                          data={servicesTableData}
                          onRemove={handleRemoveRow}
                          addService={addServiceHandler}
                          options={servicesOptions[language]}
                          requestIsAnalyzed={requestIsAnalyzed}
                          onChangeService={handleChangeServiceRow}
                          declineOptions={declineOptions[language]}
                          onChangeServiceName={handleChangeServiceName}
                          onApproveAllServices={handleApproveAllServices}
                          onDeclineAllServices={handleDeclineAllServices}
                          onChangeDeclineReason={handleChangeDeclineReason}
                        />
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </Row>
            )}
          </Container>
        )}
        {/* buttonBlock */}
        {visibleTables && (
          <Container fluid className="button-block">
            <Row className="default-block">
              <Col md="4" className="column">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-analyzed">
                      {t('tip.analyzeRequest')}
                    </Tooltip>
                  }
                >
                  <Button
                    className="action-button"
                    variant="primary"
                    disabled={
                      !requestIsClassified ||
                      isLoading ||
                      !servicesTableData.length ||
                      requestIsApproved
                    }
                    onClick={handleAnalyze}
                  >
                    {t('actions.analyzeRequest')}
                  </Button>
                </OverlayTrigger>
              </Col>
              <Col md="5" className="column">
                <UsefulBlock
                  countsBefore={countsBefore}
                  services={servicesTableData}
                  diagnoses={diagnosisTableData}
                  onDownload={handleExcelDownload}
                  requestIsAnalyzed={requestIsAnalyzed}
                />
              </Col>
              <Col md="3" className="column">
                <OverlayTrigger
                  placement="left-start"
                  overlay={
                    <Tooltip
                      id="tooltip-disabled-approve"
                      className="tooltip-approve"
                      style={requestIsApproved ? {} : { display: 'none' }}
                    >
                      {t('tip.approvingCompleted')}
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <Button
                      className="action-button"
                      variant="success"
                      disabled={
                        !requestIsAnalyzed || requestIsApproved || isLoading
                      }
                      onClick={handleApproveTask}
                    >
                      {requestIsApproved ? (
                        <>
                          <Done /> {t('actions.approved')}
                        </>
                      ) : (
                        <>{t('actions.approve')}</>
                      )}
                    </Button>
                  </span>
                </OverlayTrigger>
              </Col>
            </Row>
          </Container>
        )}
        {isLoadingLocal && <OverLayLoader />}
      </CustomContentContainer>
    </Container>
  );
};

MainView.propTypes = {
  urlId: PropTypes.string,
  urlText: PropTypes.string,
  urlCheckable: PropTypes.string,
};

export default MainView;
