import PropTypes from 'prop-types';
import { components } from 'react-select';
import './CustomSelectOption.css';

const CustomSelectOption = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };

  return (
    <components.Option {...newProps} className="custom-option">
      {children}
    </components.Option>
  );
};

CustomSelectOption.propTypes = {
  children: PropTypes.string,
  props: PropTypes.object,
};

export default CustomSelectOption;
