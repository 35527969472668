import { useState, useEffect, useMemo } from 'react';
import { Container, Button, Row, Col, Dropdown, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import WindowedSelect, { createFilter } from 'react-windowed-select';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import PlainTable from '../PlainTable';
import NormativeActItem from '../NormativeActItem';
import CustomSelectOption from '../CustomSelectOption';
import Eye from '../../assets/icons/Eye';
import Copy from '../../assets/icons/Copy';
import Trash from '../../assets/icons/Trash';
import Expand from '../../assets/icons/Expand';
import Compress from '../../assets/icons/Compress';
import './DiagnosisTable.css';

const styles = {
  top: '0',
  minHeight: '35vh',
  height: 'fit-content',
  maxHeight: '50vh',
  boxShadow: '-1px 1px 4px grey',
};

const DiagnosisTable = ({
  data,
  options,
  onRemove,
  isLoading,
  addDiagnosis,
  onChangeDiagnosis,
  onChangeDiagnosisName,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (isExpanded) {
      window.addEventListener('keydown', handleEsc);

      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }
  });

  const handleEsc = e => {
    if (e.code === 'Escape') {
      setIsExpanded(false);
    }
  };

  const handleToggleExpand = () => {
    setIsExpanded(prev => !prev);
  };

  const columns = useMemo(
    () => [
      {
        id: 'copyName',
        style: {
          width: '4%',
        },
        Cell: ({ row: { original } }) => (
          <CopyToClipboard text={original.diagnosis_name}>
            <Button
              size="sm"
              variant="outline-light"
              className="action-icon action-icon__copy mx-auto"
              title={t('actions.copyName', { name: t('diagnoses.name') })}
            >
              <Copy />
            </Button>
          </CopyToClipboard>
        ),
      },
      {
        Header: t('diagnoses.diagnosisName'),
        accessor: 'diagnosis_name',
        style: {
          width: '55%',
        },
        Cell: ({ value, row: { index, original } }) => {
          return (
            <div title={value}>
              <WindowedSelect
                // menuIsOpen
                id={'Diagnosis-' + original.id}
                options={options}
                value={{ value, label: value }}
                openMenuOnFocus
                placeholder={null}
                isSearchable={true}
                maxMenuHeight={210}
                isLoading={isLoading}
                captureMenuScroll={false}
                menuShouldScrollIntoView={false}
                filterOption={createFilter({ ignoreAccents: false })}
                loadingMessage={() => t('tip.loading')}
                noOptionsMessage={() => t('diagnoses.notFound')}
                onChange={selected => onChangeDiagnosisName(selected, index)}
                classNamePrefix="custom-select"
                components={{
                  Option: CustomSelectOption,
                }}
              />
            </div>
          );
        },
      },

      {
        accessor: 'id',
        style: {
          width: '7%',
        },
        Cell: ({ value }) => {
          return (
            <Button
              size="sm"
              variant="outline-light"
              className="action-icon action-icon__trash mx-auto"
              title={t('diagnoses.diagnosisDelete')}
              onClick={() => onRemove(value, 'diagnoses')}
            >
              <Trash />
            </Button>
          );
        },
      },
      {
        Header: t('diagnoses.exacerbation'),
        accessor: 'exacerbation',
        style: {
          width: '14%',
        },
        Cell: ({
          value,
          row: { index, original },
          column: { id: columnName },
        }) => {
          return (
            <Container className="form-check exacerbation-check">
              <Form.Check
                inline
                type="checkbox"
                checked={value}
                label={null}
                name="group1"
                id={'Exacerbation-' + original.id}
                onChange={e =>
                  onChangeDiagnosis({
                    columnName,
                    index,
                    value: e.target.checked,
                  })
                }
              />
            </Container>
          );
        },
      },
      {
        Header: t('diagnoses.normativeAct'),
        accessor: 'normative_act',
        style: {
          width: '20%',
        },
        Cell: ({ value, row: { original } }) => {
          if (value.length > 1) {
            return (
              <Dropdown drop="start">
                <Dropdown.Toggle
                  variant="none"
                  id={'Dropdown-' + original.id}
                  bsPrefix="dropdown_btn"
                  title={t('actions.viewing')}
                >
                  <Eye />
                </Dropdown.Toggle>

                <Dropdown.Menu align="start">
                  {value.map((act, idx) => (
                    <Dropdown.Item key={idx} as="div">
                      <NormativeActItem act={act} />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            );
          } else if (value.length === 1) {
            return <NormativeActItem act={value[0]} />;
          } else {
            return '';
          }
        },
      },
    ],
    [isLoading, onChangeDiagnosis, onChangeDiagnosisName, onRemove, options, t],
  );

  return (
    <Container
      fluid
      style={isExpanded ? styles : {}}
      className={'list expandable-list'}
    >
      <div className="expand-btn-holder">
        <Button
          size="sm"
          className="expand-btn"
          variant="outline-primary"
          onClick={handleToggleExpand}
          title={isExpanded ? t('actions.shrink') : t('actions.expand')}
        >
          {isExpanded ? <Compress /> : <Expand />}
        </Button>
      </div>
      <Container id="DiagnosisList" fluid>
        <PlainTable data={data} columns={columns} />

        <Row className="btn-row">
          <Col md="11">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={e => {
                addDiagnosis(e);
                setIsExpanded(true);
              }}
            >
              {t('actions.addDiagnosis')}
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

DiagnosisTable.propTypes = {
  onRemove: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  addDiagnosis: PropTypes.func.isRequired,
  onChangeDiagnosis: PropTypes.func.isRequired,
  onChangeDiagnosisName: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DiagnosisTable;
